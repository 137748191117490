import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout } from "../components/organisms/Layout"
import Masthead from "../components/molecules/Masthead"
import ExternalLink from "../components/atoms/ExternalLink"
import SEO from "../components/seo"
import SignUp from "../components/organisms/SignUp"

const PortfolioGrid = styled.section`
  grid-column: 2/-2;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 4rem;
  @media (min-width: 992px) {
    grid-gap: 3rem;
  }
`
const Project = styled.article`
  grid-column: 1/-1;
  margin-bottom: 3rem;
  h3 {
    font-size: ${({ theme }) => theme.typography.h5};
    color: ${({ theme }) => theme.colors.accentColorInverse};
    span {
      background: ${({ theme }) => theme.colors.linkUnderline};
      background-size: 100% 0.5rem;
      background-repeat: no-repeat;
      background-position: left 85%;
    }
  }
  @media (min-width: 992px) {
    grid-column: span 6;
  }
`

Project.Img = styled.div`
  padding: 3rem;
  background: ${({ theme }) => theme.colors.gradientTopRight};
  height: 300px;
  margin-bottom: 1rem;
  display: flex;
  img {
    display: block;
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0 auto;
    flex: 1;
  }
  @media (min-width: 1200px) {
    height: 400px;
  }
`
Project.Body = styled.div`
  h3,
  p {
    margin-bottom: 1rem;
  }
`

export const query = graphql`
  query AllProjectQuery {
    allSanityProject(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        title
        url
        body {
          children {
            text
          }
        }
        mainImage {
          asset {
            url
          }
        }
        id
      }
    }
  }
`

const WorkPage = ({ data }) => {
  const projects = data.allSanityProject.nodes
  return (
    <Layout>
      <SEO title="Work" />
      <Masthead>
        <h1 style={{ marginBottom: "1rem", fontSize: "3rem" }}>
          Recent Projects
        </h1>
        <p>
          I work closely with each and every client to make sure that the end
          product is exactly what they envisioned. Take a look below to see some
          examples of my past projects.
        </p>
      </Masthead>
      <PortfolioGrid>
        {projects.map(project => (
          <Project key={project.id}>
            <Project.Img>
              <img src={project.mainImage.asset.url} alt={project.title} />
            </Project.Img>
            <Project.Body>
              <h3>
                <span>{project.title}</span>
              </h3>
              <p>{project.body[0].children[0].text}</p>
              <ExternalLink
                href={project.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>View Project</span>
              </ExternalLink>
            </Project.Body>
          </Project>
        ))}
      </PortfolioGrid>
      <SignUp />
    </Layout>
  )
}

export default WorkPage
